import {Canvas} from "@react-three/fiber";
import Ring from "./ring";

export default function Scene() {
    return (
        <Canvas className="bg-black">
            <Ring text="MAAAAAAAAIIIEEEEE" radius={2} height={2} segments={10}/>
        </Canvas>
    );
}
