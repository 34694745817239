
import { Toaster } from 'react-hot-toast';
import Scene from "./scene";



export default function App() {
    return (
        <main className="h-screen w-screen bg-black">
            <Scene />
            <Toaster/>
        </main>
    );
}
